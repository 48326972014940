<template>
  <Events :query="query" :going="true"></Events>
</template>

<script>
import Events from "@/components/Event/Events";
import store from "@/store";

export default {
  components: { Events },
  methods: {
    calculate_age(dob) {
      var diff_ms = Date.now() - dob.getTime();
      var age_dt = new Date(diff_ms);

      return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
  },
  computed: {
    query() {
      const age = this.calculate_age(new Date(store.state.user.DoB));
      if (age >= 18) {
        return event =>
          event
            .where(
              "date",
              ">",
              new Date(new Date().setDate(new Date().getDate() - 1))
            )
            .orderBy("date");
      } else {
        return event =>
          event
            .where(
              "date",
              ">",
              new Date(new Date().setDate(new Date().getDate() - 1))
            )
            .where("over18", "==", false)
            .orderBy("date");
      }
    }
  }
};
</script>
